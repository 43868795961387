//***  OPEN MODAL CEREMONIAL LOCATION   ***//

const ceremonyModal = document.getElementById('modalCeremony');
ceremonyModal.style.display = 'none';

const openCeremonyModal = document.getElementById('openCeremony');
openCeremonyModal.addEventListener('click', () => {
	ceremonyModal.style.display = 'flex';
});

const closeCeremonialsModalBtn = document.getElementById(
	'closeCeremonialsModalBtn'
);
closeCeremonialsModalBtn.addEventListener('click', () => {
	ceremonyModal.style.display = 'none';
});

const openCeremonialMap = document.getElementById('redirectMapCeremonial');
openCeremonialMap.addEventListener('click', () => {
	window.open(
		'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d581.9890810707806!2d-77.58322233376022!3d-11.102923046586872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9106dfeba8b43c23%3A0x9dd6988da7cece6!2sEl%20Milagro%20900%2C%20Huacho%2015137%2C%20Per%C3%BA!5e0!3m2!1ses-419!2sve!4v1702335014578!5m2!1ses-419!2sve',
		'_blank'
	);
});

window.addEventListener('click', (event) => {
	if (event.target === ceremonyModal) {
		ceremonyModal.style.display = 'none';
	}
});

//***  OPEN MODAL CELEBRATION LOCATION   ***//

const celebrationModal = document.getElementById('modalCelebration');
celebrationModal.style.display = 'none';

const openCelebrationModal = document.getElementById('openCelebration');
openCelebrationModal.addEventListener('click', () => {
	celebrationModal.style.display = 'flex';
});

const closeModalCelebration = document.getElementById('closeModalCelebration');
closeModalCelebration.addEventListener('click', () => {
	celebrationModal.style.display = 'none';
});

const openCelebrationMap = document.getElementById('redirectMapCelebration');
openCelebrationMap.addEventListener('click', () => {
	window.open(
		'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d581.9890810707806!2d-77.58322233376022!3d-11.102923046586872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9106dfeba8b43c23%3A0x9dd6988da7cece6!2sEl%20Milagro%20900%2C%20Huacho%2015137%2C%20Per%C3%BA!5e0!3m2!1ses-419!2sve!4v1702335014578!5m2!1ses-419!2sve',
		'_blank'
	);
});

window.addEventListener('click', (event) => {
	if (event.target === celebrationModal) {
		celebrationModal.style.display = 'none';
	}
});

// //***  OPEN MODAL TABLE GIFT   ***//

// const modalTableGift = document.getElementById('modalTableGift');
// modalTableGift.style.display = 'none';

// const btnOpenModalGift = document.getElementById('btnOpenModalGift');
// btnOpenModalGift.addEventListener('click', () => {
// 	modalTableGift.style.display = 'flex';
// });

// const closeModalTableGift = document.getElementById('closeModalTableGift');
// closeModalTableGift.addEventListener('click', () => {
// 	modalTableGift.style.display = 'none';
// });

// const btnFalabella = document.getElementById('btnFalabella');
// btnFalabella.addEventListener('click', () => {
// 	window.open('https://www.falabella.com.pe/falabella-pe', '_blank');
// });

// window.addEventListener('click', (event) => {
// 	if (event.target === modalTableGift) {
// 		modalTableGift.style.display = 'none';
// 	}
// });

// const copyAccount = document.getElementById('copyAccount');
// copyAccount.addEventListener('click', () => {
// 	const account = '0022-0156-0200368345';
// 	navigator.clipboard
// 		.writeText(account)
// 		.then(function () {
// 			console.log('Texto copiado al portapapeles');
// 		})
// 		.catch(function (err) {
// 			console.error('Error al copiar texto: ', err);
// 		});
// });


// const copyCCI = document.getElementById('copyCCI');
// copyCCI.addEventListener('click', () => {
// 	const CCI = 'CCI: 011-101-0022-0156-0200368345';
// 	navigator.clipboard
// 		.writeText(CCI)
// 		.then(function () {
// 			console.log('Texto copiado al portapapeles');
// 		})
// 		.catch(function (err) {
// 			console.error('Error al copiar texto: ', err);
// 		});
// });


// CONFIRM ASSISTANCE 

const btnConfirmAsistance = document.getElementById('btnConfirmAsistance');
const btnDeclineAsistance = document.getElementById('btnDeclineAsistance');

btnConfirmAsistance.addEventListener('click', () => {
	let phoneNumber = '+51930122452';
	let encodedMessage = encodeURIComponent(
		'Es un honor para mí ser parte de este momento tan importante en sus vidas. Soy ___________ y confirmo mi asistencia a la boda. Les envío un fuerte abrazo. 🤗👏'
	);

	const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

	window.open(url, '_blank');
	document.querySelector("#confirmName").innerHTML = `¡Muchas gracias por haber confirmado tu invitación a nuestra boda, esperamos que disfrutes de este hermoso momento tanto como nosotros!`;
	hideButtons();
});

btnDeclineAsistance.addEventListener('click', () => {
	let phoneNumber = '+51930122452';
	let encodedMessage = encodeURIComponent(
		'Lamento mucho no poder asistir a la boda. Soy ___________ y les agradezco de corazón la invitación. Tienen mi apoyo y mi cariño en este día tan importante. Espero que disfruten de la celebración y que sean muy felices. 😢🙏'
	);

	const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

	window.open(url, '_blank');

	document.querySelector("#confirmName").innerHTML = `Lamentamos que no puedas asistir. Si cambias de opinión, por favor contáctanos. ¡Deseamos encontrarnos en otra oportunidad!`;
	hideButtons();
});

export function hideButtons() {
	const confirmButton = document.getElementById("btnConfirmAsistance");
	const declineButton = document.getElementById("btnDeclineAsistance");
	confirmButton.style.display = "none";
	declineButton.style.display = "none";
}
	  